<template>

  <div>
    <!-- Filters -->
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>Date</label><br>
          <date-range-picker
            v-model="pickerDates"
            :config="config"
            :locale-data="locale"
            @update="dateChange"
    >
    </date-range-picker>
    </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
          v-if="userRole!=='branch'"
        >
          <label>Branch</label><br>
          <v-select
                v-model="branchId"
                :dir="'ltr'"
                :options="branches"
                :reduce="val => val.value"
                :clearable="false"
                input-id="branchId"
              />
    </b-col>
      </b-row>
    </b-card-body>
  </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Search -->
          <b-col
            cols="1"
            md="12">
          <download-excel class="btn btn-success float-right" :data="fetchInvoicesUnsliced" :fields="invoicesAllFields"
  worksheet="Invoices"
  name="invoice_void.xls">
          Export Excel
          </download-excel>
          </b-col>
        </b-row>

      </div>

      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          ><vue-table-dynamic
      ref="refInvoicesListTable"
      :params="fetchInvoices">
           <template v-slot:column-0="{ props }">
           <span>{{ props.row }}</span>
           </template>
           <template v-slot:column-7="{ props }">
              <span class="align-text-top text-capitalize">{{ parseInt(props.cellData.FlagInvoiceAttachment) > 0 ? 'Yes' : 'No' }}
                <feather-icon v-if="parseInt(props.cellData.FlagInvoiceMessage)>0" style="color:#036bfc" icon="MessageCircleIcon" />
              <feather-icon v-if="parseInt(props.cellData.FlagInvoiceAttachment)>0" style="color:#b6ba3c" icon="FileIcon" /></span>
        </template>
      </vue-table-dynamic>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BPagination,
  BCardHeader, BCardBody, VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from 'axios'
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { getToken } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import useInvoicesList from './useInvoicesList'

const userToken = getToken()
const userRole = ''
const headers = {
  'Content-Type': 'application/json',
  'X-Token-Access': `Bearer ${userToken}`,
}
const fetchInvoicesData = [['No', 'Customer', 'Job No', 'Inv No', 'Inv Date', 'Ref No', 'Inv Pusat No', 'Inv Pusat Date', 'Date Input', 'Void Date']]
const InvoiceId = ''
const modalUploadShow = false
const uploadInvoiceId = ''
const InvoiceDate = ''
const InvoiceNumber = ''
const modalShow = false
const fileAttachments = []
const fetchInvoicesUnsliced = []
const startDateRaw = new Date()
const endDateRaw = new Date()
startDateRaw.setDate(startDateRaw.getDate() - 30)
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    DateRangePicker,
    BTable,
    BPagination,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  setup() {
    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      branchId,
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoicesListTable,
      refetchData,
      startDate,
      endDate,
      // UI
      resolveInvoicesRoleVariant,
      resolveInvoicesRoleIcon,
      resolveInvoicesStatusVariant,

    } = useInvoicesList()

    return {

      // Sidebar
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoicesListTable,
      refetchData,
      startDate,
      endDate,
      // UI
      resolveInvoicesRoleVariant,
      resolveInvoicesRoleIcon,
      resolveInvoicesStatusVariant,
      statusOptions,
      InvoiceId,
      InvoiceDate,
      InvoiceNumber,
      branchId,
    }
  },
    watch: {
        branchId: 'getAllInvoices',
    },
  data() {
  const invoicesAllFields = {
      Customer: 'Customer',
      'Job No': 'JobNo',
      'Inv Number': 'InvoiceNumber',
      'Inv   Date': 'InvoiceDate',
      Ref: 'Reference',
      Currency: 'Currency',
      DPP: 'DPP',
      Tax: 'Tax',
      'Grand Total': 'GrandTotal',
      'Input Pusat Date': 'Pusat_Timestamp',
      'InV Date pusat': 'Pusat_InvoiceDate',
      'No Inv Pusat': 'Pusat_NoInvoice',
      Confirmed: {
        field: 'invoiceApproved',
        callback: value => (value === 1 ? 'Yes' : 'No'),
      },
      'Supporting Doc': {
        field: 'FlagInvoiceAttachment',
        callback: value => (parseInt(value, 8) > 0 ? 'Yes' : 'No'),
      },
      'Void Date': 'VoidDate',
    }
      return {
        fetchInvoices: {
          data: fetchInvoicesData,
          header: 'row',
          enableSearch: true,
          border: true,
          stripe: true,
          pagination: true,
          pageSize: 10,
          pageSizes: [10, 20, 30, 40, 50],
          showTotal: true,
          columnWidth: [{ column: 0, width: 50 } , { column: 1, width: 310 }, { column: 4, width: 100 }, { column: 3, width: 100 }, { column: 2, width: 100 }, { column: 6, width: 120 }, { column: 7, width: 90 }, { column: 8, width: 120 }, { column: 5, width: 100 }],
        },
        branches: [],
        fetchInvoicesUnsliced,
        invoicesAllFields,
        modalUploadShow,
        uploadInvoiceId,
        file: '',
        fileAttachments,
        modalShow,
        config: {
              wrap: true,
              dateFormat: 'Y-m-d',
          },
        pickerDates: {
            startDate: this.dataMeta.startDate ? this.dataMeta.startDate : startDateRaw,
            endDate: this.dataMeta.endDate ? this.dataMeta.endDate : endDateRaw,
        },
        locale: {
          direction: 'ltr',
          format: 'yyyy/mm/dd',
          separator: ' - ',
          applyLabel: 'Apply',
          cancelLabel: 'Cancel',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          firstDay: 1,
        },
      }
    },
    async mounted() {
      this.userRole = this.$cookies.get('UserRole')

    await this.getAllInvoices()
    this.getAllInvoicesUnsliced()
    this.getBranches()
    },
  methods: {
    async getAllInvoices() {
    await this.emptyArray()
    const userKodeUnitKerja = this.$cookies.get('KodeUnitKerja')
    this.userRole = this.$cookies.get('UserRole')
    const body = {
          DateFrom: moment(this.startDate).format('YYYY-MM-DD'),
          DateTo: moment(this.endDate).format('YYYY-MM-DD'),
      }
      console.log(body)
      console.log('body')
    axios
      .post('https://api.geolims.com/invoice_service/getvoid', body, { headers })
      .then(response => {
        response.data.Payload.map(o => {
          const unitkerja = o.JobNo.toString().substring(0, 3)
        const row = []
            row.push('')
            row.push(o.Customer)
            row.push(o.JobNo)
            row.push(o.InvoiceNumber)
            row.push(this.dateFormat(o.InvoiceDate, 'MM/DD/YYYY'))
            row.push(o.Agreement !== null ? o.Agreement : '-')
            row.push(o.Pusat_NoInvoice !== null ? o.Pusat_NoInvoice : '-')
            row.push(o.Pusat_InvoiceDate !== null ? this.dateFormat(o.Pusat_InvoiceDate, 'MM/DD/YYYY') : '-')
            row.push(this.dateFormat(o.InputTime))
            row.push(o.VoidDate !== null ? this.dateFormat(o.VoidDate, 'MM/DD/YYYY') : '-')
        if (this.branchId !== '' && this.branchId !== null) {
          if (this.branchId.toString() === unitkerja.toString()) {
          fetchInvoicesData.push(row)
          }
        } else if (this.userRole === 'branch') {
          if (userKodeUnitKerja.toString() === unitkerja.toString()) {
          fetchInvoicesData.push(row)
          }
        } else {
        fetchInvoicesData.push(row)
        }
        })
      })
      .catch(e => {
        console.log(e)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching invoice list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      },
    async dateChange(val) {
      this.startDate = val.startDate
      this.endDate = val.endDate
      await this.emptyArray()
      await this.getAllInvoices()
      this.getAllInvoicesUnsliced()
    },
    searchChange({ type, target }) {
      console.log(type)
      console.log('woi', this.searchQuery)
      this.searchQuery = target.value
    },
    dateFormat(value, format = 'MM/DD/YYYY hh:mm') {
        return moment(String(value)).format(format)
    },
    dateSimple(value) {
        let dateRet = ''
        if (value != null) {
          dateRet = moment(String(value)).format('YYYY-MM-DD')
        } else { dateRet = moment().format('YYYY-MM-DD') }
        return dateRet
    },
    getBranches() {
          axios
              .get(process.env.VUE_APP_API_GET_BRANCH, { headers })
              .then(response => {
                const branchesJoin = response.data.Payload.map(elem => {
                  return { label: `${elem.Deskripsi} (${elem.KodeUnitKerja})`, value: elem.KodeUnitKerja }
                })
                this.branches = branchesJoin.sort((a, b) => parseInt(a.value) > parseInt(b.value) ? 1 : -1)
              })
              .catch(err => {
                  console.log(err)
              })
      },

    numberFormat(value) {
      return parseFloat(value).toFixed(2)
    },
      getAllInvoicesUnsliced() {
    const userKodeUnitKerja = this.$cookies.get('KodeUnitKerja')
        console.log('Unsliced adata ready!')
    const body = {
          DateFrom: moment(this.startDate !== '' ? this.startDate : startDateRaw).format('YYYY-MM-DD'),
          DateTo: moment(this.endDate !== '' ? this.endDate : endDateRaw).format('YYYY-MM-DD'),
      }
      console.log('Body nya nih bos', body)
    axios
      .post('https://api.geolims.com/invoice_service/getvoid', body, { headers })
      .then(response => {
        console.log(response)
        const all = []
        response.data.Payload.map(o => {
          const unitkerja = o.JobNo.toString().substring(0, 3)
          if (userRole === 'branch') {
            if (userKodeUnitKerja.toString() === unitkerja.toString()) {
              all.push(o)
            }
          } else all.push(o)
          return o
        })
        this.fetchInvoicesUnsliced = all
        console.log('unsliced here!')

        return ''
      })
      .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Get Data Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
      })
      },
      async emptyArray() {
        const header = ['No', 'Customer', 'Job No', 'Inv No', 'Inv Date', 'Ref No', 'Inv Pusat No', 'Inv Pusat Date', 'Date Input', 'Void Date']
        fetchInvoicesData.length = 0
        while (fetchInvoicesData.length > 0) {
          fetchInvoicesData.pop()
        }
        fetchInvoicesData.push(header)
      },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
